import DateHelper from 'app/utilities/date-helper';
import formatTitleDateRange from '../utilities/format-title-date-range';
import Page from './page';
import Performance from './performance';
import Venue from './venue';

// All prop getters need to be safe on undefined values
// because this model is used for both listing and details view
// In listing view (e.g. on venue page) the API only returns a subset of the data
export default class Show extends Page {
    constructor(data, included) {
        super(data, included);

        this._performances = [];
        this._performanceGroups = {};
        this._venues = undefined;
    }

    /**
     * Get Accessibility
     *
     * @return {object}
     */
    get accessibility() {
        return {
            'wheelchair-accessible': (this.props.wheelchair_accessible === true ? 'Accessible seating available' : false),
            'virtual-tours': (this.props.virtual_tour === true ? 'Accessibility virtual tour' : false),
            'hearing-aid-loop': (this.props.hearing_aid_loop === true ? 'Hearing aid loop performances' : false),
            'sign-language-interpreted': (this.props.sign_language_interpreted === true ? 'Sign language performances' : false),
            'audio-described': (this.props.audio_described === true ? 'Audio described performances' : false),
            'touch-tours': (this.props.touch_tours === true ? 'Touch tour performances' : false),
        };
    }

    /**
    * Get Additional Information
    *
    * @return {string}
    */
    get additionalInformation() {
        return this.props.additional_information;
    }

    /**
     * Get Artistic Credit
     *
     * @return {string}
     */
    get artisticCredit() {
        return this.props.artistic_credit;
    }

    /**
     * Get Content Blocks
     *
     * @return {array}
     */
    get blocks() {
        return this.props.blocks || [];
    }

    /**
     * Get Company
     *
     * @return {string}
     */
    get company() {
        return this.props.company;
    }

    /**
     * Get Date
     * - TODO: DRY this with Event model date()
     *
     * @return {string}
     */
    get date() {
        return formatTitleDateRange(this.props.start_date, this.props.end_date, false);
    }

    /**
     * Get Title Date
     *
     * @return {string}
     */
    get titleDate() {
        return formatTitleDateRange(this.props.start_date, this.props.end_date);
    }

    /**
     * Get Genre
     *
     * @return {string}
     */
    get genre() {
        return this.props.genres && this.props.genres[0] && this.props.genres[0].description || undefined;
    }

    /**
     * Get Hero Image
     *
     * @return {object}
     */
    get heroImage() {
        let desktop = '',
            mobile = '';

        if (this.props.hero_image) {
            if (this.props.hero_image.images.desktop) {
                desktop = this.props.hero_image.images.desktop[0];
            }

            if (this.props.hero_image.images.mobile) {
                mobile = this.props.hero_image.images.mobile[0];
            }
        }

        return {
            desktop: desktop,
            mobile: mobile
        };
    }

    /**
     * Get Hero Images
     *
     * @return {array}
     */
    get heroImages() {
        if (!this.props.hero_image) {
            return [];
        }

        return this.props.hero_image.images.desktop;
    }

    /**
     * Get Hero Theme
     *
     * @return {string}
     */
    get heroTheme() {
        if (this.props.hero_image && this.props.hero_image.theme) {
            return this.props.hero_image.theme;
        }

        return 'blue';
    }

    /**
     * Get Hero Video
     *
     * @return {string}
     */
    get heroVideo() {
        if (this.props.hero_image && this.props.hero_image.video) {
            return this.props.hero_image.video;
        }

        return '';
    }

    /**
     * Get Featured (Y/N)
     *
     * @return {boolean}
     */
    get isFeatured() {
        return this.props.is_featured;
    }

    /**
     * Get Featured Order
     *
     * @return {number}
     */
    get featuredOrder() {
        return this.props.featured_order;
    }

    /**
     * Get Free (Y/N)
     *
     * @return {boolean}
     */
    get isFree() {
        return this.props.is_free;
    }

    /**
     * Get Past (Y/N)
     *
     * @return {boolean}
     */
    get isPast() {
        const completion = this.props.end_date;
        const today = DateHelper.today;

        return today.isAfter(completion, 'day');
    }

    /**
     * Get Highlighted (Y/N)
     *
     * @return {boolean}
     */
    get isHighlighted() {
        return this.props.is_highlighted;
    }

    /**
     * Get Long Season (Y/N)
     *
     * @return {boolean}
     */
    get longSeason() {
        return this.props.long_season;
    }

    /**
     * Get Calendar View (Y/N)
     *
     * @return {boolean}
     */
    get calendarView() {
        return this.props.calendar_view;
    }

    /**
     * Get Page Title
     *
     * @return {string}
     */
    get pageTitle() {
        const name = this.name;
        if (name) {
            const titleDate = this.titleDate;
            if (titleDate) {
                return `${name} | ${titleDate}`;
            }

            return name;
        }

        return undefined;
    }

    /**
     * Get Performance Description
     *
     * @return {string}
     */
    get performanceDescription() {
        return this.props.performance_description || false;
    }

    /**
     * Get Performance Description
     *
     * @return {string}
     */
    get gaEventTracking() {
        return this.props.ga_event_tracking || false;
    }

    /**
     * Get an array of performances for this Show
     * grouped by the month(short name) of the performance
     *
     * @return {array}
     */
    get performanceGroups() {
        if (!Object.getOwnPropertyNames(this._performanceGroups).length) {
            this.performances.forEach((performance) => {
                const month = performance.date.month.toLowerCase();
                if (this._performanceGroups[month] === undefined) {
                    this._performanceGroups[month] = {
                        name: month,
                        performances: []
                    };
                }

                this._performanceGroups[month].performances.push(performance);
            });
        }

        return this._performanceGroups;
    }

    /**
     * Get Current Performances
     *
     * @return {array}
     */
    get performances() {
        // TODO: Remove this unique performances logic in case it is fixed in the API.
        const performances = this.props.performances || [];
        const uniqueProp = 'starts_at';

        // Creates an array with all start_at props from the performances array.
        const startAtArray = performances.map((mapObj) => mapObj[uniqueProp]);

        // Filter all performances by start_at prop avoiding returning duplicated performances
        const uniquePerformances = performances.filter((obj, pos) => {
            return startAtArray.indexOf(obj[uniqueProp]) === pos;
        });

        if (uniquePerformances && !this._performances.length) {
            uniquePerformances.forEach((performance) => {
                const _performance = new Performance(performance, this.venues); // eslint-disable-line no-underscore-dangle
                if (!_performance.isBeforeCurrentMonth) {
                    this._performances.push(_performance.toJSON());
                }
            });

            // Restore the order of performances
            this._performances.sort((a, b) => a.order - b.order);

            // If the show is long-season, we only need to display the last performance
            if (this.longSeason) {
                this._performances = this._performances.slice(-1);
            }
        }

        return this._performances;
    }

    /**
     * Get Performances in the JSON-LD Format
     *
     * @return {array}
     */
    get performancesStructuredData() {
        if (!this.props.performances || this.props.performances.length === 0) return '';

        return this.props.performances.reduce((prev, current) => {
            const _performance = new Performance(current, this.venues); // eslint-disable-line no-underscore-dangle

            if (!_performance.isPast) {
                prev.push({
                    '@context': 'http://schema.org',
                    '@type': 'Event',
                    name: this.name,
                    image: this.heroImage.desktop,
                    startDate: _performance.startsAt,
                    url: `https://www.aucklandlive.co.nz${this.url}`,
                    description: this.seo.description,
                    location: _performance.locationStructuredData,
                    organizer: {
                        '@type': 'Organization',
                        name: 'Auckland Live',
                        url: 'https://www.aucklandlive.co.nz'
                    }
                });
            }

            return prev;
        }, []);
    }

    /**
     * Get Presenter
     *
     * @return {string}
     */
    get presenter() {
        return this.props.presenter;
    }

    /**
     * Get Pricing Block for Ticketing
     *
     * @return {array}
     */
    get pricingBlock() {
        const pricing = this.props.pricing || [];

        if (pricing.length) {
            pricing[0].data = Array.from(pricing[0].data);
        }

        return pricing;
    }

    /**
     * Get content blocks that should render after performances
     *
     * @return {array}
     */
    get blocksAfterPerformances() {
        return this.props.blocks_after_performances || [];
    }

    /**
     * Get Price (range)
     * 'Free' if no value is provided
     *
     * @return {string}
     */
    get price() {
        if (this.props.isFree) {
            return 'Free';
        }

        return this.props.price || 'Free';
    }

    /**
     * Get Seating Plan Chart URL
     *
     * @return {string}
     */
    get seatingPlanChart() {
        return this.props.seatingPlanChart;
    }

    /**
     * Get Specifications
     *
     * @return {object}
     */
    get specifications() {
        return {
            'artists': this.props.artists,
            'director': this.props.director,
            'duration': this.props.duration,
            'age-restriction': this.props.age_restriction
        };
    }

    /**
     * Get Subtitle
     *
     * @return {string}
     */
    get subtitle() {
        return this.props.subtitle;
    }

    /**
     * Get Theme
     *
     * @return {string}
     */
    get theme() {
        return this.props.highlight_theme;
    }

    /**
     * Get URL's of thumbnails
     *
     * @return {object}
     */
    get thumbnail() {
        return {
            landscape: this.props.landscape_thumbnail || '',
            square: this.props.square_thumbnail || ''
        };
    }

    /**
     * Get Venue string
     *
     * @return {string}
     */
    get venue() {
        return this.props.venue_name;
    }

    /**
     * Get Venues
     *
     * @return {array}
     */
    get venues() {
        if (!this._venues) {
            this._venues = this.getRelationship('venues', Venue);
        }

        return this._venues;
    }

    /**
     * Get Hero data object
     *
     * @return {object}
     */
    get hero() {
        return {
            date: this.date,
            genre: this.genre,
            image: this.heroImage,
            images: this.heroImages,
            isFree: this.isFree,
            isPast: this.isPast,
            location: this.venue,
            name: this.name,
            price: this.price,
            theme: this.heroTheme,
            url: this.url,
            video: this.heroVideo
        };
    }

    /**
     * Get Tile data object
     *
     * @return {object}
     */
    get tile() {
        return {
            company: this.company,
            date: this.date,
            genre: this.genre,
            isFeatured: this.isFeatured,
            isHighlighted: this.isHighlighted,
            isPast: this.isPast,
            name: this.name,
            presenter: this.presenter,
            price: this.price,
            theme: this.theme,
            thumbnail: this.thumbnail,
            type: 'show',
            url: `/show/${this.slug}`,
            venue: this.venue
        };
    }

    get performancesTiles() {
        return {
            company: this.company,
            date: this.date,
            genre: this.genre,
            isFeatured: this.isFeatured,
            isHighlighted: this.isHighlighted,
            isPast: this.isPast,
            name: this.name,
            presenter: this.presenter,
            price: this.price,
            theme: this.theme,
            thumbnail: this.thumbnail,
            type: 'show',
            url: `/show/${this.slug}`,
            venue: this.venue,
            performances: this.props.performances
        };
    }
}

Object.defineProperties(Show.prototype, {
    'accessibility':               { enumerable: true },
    'additionalInformation':       { enumerable: true },
    'artisticCredit':              { enumerable: true },
    'blocks':                      { enumerable: true },
    'calendarView':                { enumerable: true },
    'company':                     { enumerable: true },
    'date':                        { enumerable: true },
    'featuredOrder':               { enumerable: true },
    'genre':                       { enumerable: true },
    'hero':                        { enumerable: true },
    'heroImage':                   { enumerable: true },
    'heroImages':                  { enumerable: true },
    'heroVideo':                   { enumerable: true },
    'heroTheme':                   { enumerable: true },
    'isFeatured':                  { enumerable: true },
    'isFree':                      { enumerable: true },
    'isPast':                      { enumerable: true },
    'isHighlighted':               { enumerable: true },
    'longSeason':                  { enumerable: true },
    'pageTitle':                   { enumerable: true },
    'performanceDescription':      { enumerable: true },
    'performancesStructuredData':  { enumerable: true },
    'performancesTiles':           { enumerable: true },
    'gaEventTracking':             { enumerable: true },
    'performanceGroups':           { enumerable: true },
    'performances':                { enumerable: true },
    'presenter':                   { enumerable: true },
    'pricingBlock':                { enumerable: true },
    'blocksAfterPerformances':     { enumerable: true },
    'price':                       { enumerable: true },
    'seatingPlanChart':            { enumerable: true },
    'specifications':              { enumerable: true },
    'subtitle':                    { enumerable: true },
    'theme':                       { enumerable: true },
    'thumbnail':                   { enumerable: true },
    'tile':                        { enumerable: true },
    'titleDate':                   { enumerable: true },
    'venue':                       { enumerable: true },
    'venues':                      { enumerable: true }
});
