import { clearDialogScrollPosition } from '../../utilities/scrolling-helper';
import debounce from 'debounce-promise';
import fetch from 'app/utilities/fetch';
import { formatSearchResults } from 'app/utilities/format-data';
import FullScreenDialog from './full-screen-dialog';
import Grid from './grid';
import Icon from './icon';
import { isAppProd } from 'config/env';
import Loader from './loader';
import Paginator from './paginator';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { ENDPOINTS, RESPONSE } from 'config/api';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const SearchDialog = ({ toggleSearchDialog }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const FullScreenDialogRef = useRef(null);
    const [currentPage, setCurrentPage] = useState(searchParams.get('modalPage') || 1);
    const [searchText, setSearchText] = useState(searchParams.get('keyword') || '');
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchResponse, setSearchResponse] = useState({
        results: [],
        links: {},
        count: null
    });

    const resetSearchResults = () => {
        setResults(null, {}, []);
        setError(false);
    };

    const setResults = (count, links, results, page = 1) => {
        setSearchResponse({
            count,
            links,
            results
        });
        setCurrentPage(page);
    };

    const searchTextChangeHandler = (event) => {
        setSearchText(event.target.value);
        resetSearchResults();
        debouncedSearchResultsAction(event.target.value, 1);
    };

    const buildSearchEndpoint = (keyword, page) => {
        let endpoint = `${ENDPOINTS.SEARCH}?keywords=${encodeURI(keyword)}&page=${page + 1}&is_published=true`;
        // for debugging search query in non prod envs
        // allows us to artificially set the date we are searching on instead of today
        // date format = YYYY-MM-DD
        if (!isAppProd) {
            const searchDate = searchParams.get('date');
            if (searchDate) {
                endpoint += `&date=${searchDate}`;
            }
        }

        return endpoint;
    };

    const searchResults = async(keyword, page = 1) => {
        if (!keyword) {
            return;
        }
        setIsLoading(true);
        try {
            const fetchArray = [...Array(parseInt(page)).keys()];
            const response = await Promise.all(fetchArray.map((item) => fetch(buildSearchEndpoint(keyword, item))));
            const { count, links, results } = formatSearchResults(response);
            setResults(count, links, results, page);
        } catch (err) {
            if (err.status === RESPONSE.NOT_FOUND) {
                setResults(0, {}, []);
            } else {
                setError(true);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const debouncedSearchResultsAction = useCallback(
        // eslint-disable-next-line no-magic-numbers
        debounce((keyword, page) => searchResults(keyword, page), 1000), []
    );

    const closeDialog = () => {
        toggleSearchDialog();
        clearDialogScrollPosition();
    };

    useEffect(() => {
        if (searchText) {
            searchResults(searchText, currentPage);
        }
    }, []);

    useEffect(() => {
        if (searchText) {
            searchParams.set('keyword', searchText);

            if (currentPage && currentPage > 1) {
                searchParams.set('modalPage', currentPage);
            } else {
                searchParams.delete('modalPage');
            }
        } else {
            searchParams.delete('keyword');
            searchParams.delete('modalPage');
        }
        setSearchParams(searchParams);
    }, [searchText, currentPage]);

    const paginatorClickHandler = async() => {
        try {
            const response = await fetch(buildSearchEndpoint(searchText, parseInt(currentPage)));
            const { links, results } = formatSearchResults([response]);
            setResults(searchResponse.count, links, [...searchResponse.results, ...results], parseInt(currentPage) + 1);
        } catch (err) {
            setError(true);
        }
    };

    const renderResults = () => {
        if (error) {
            return (<p className="message">Oops, looks like something went wrong. <br/>Please try your search again.</p>);
        }

        if (!searchResponse.count || searchResponse.count === 0) {
            return (
                <React.Fragment>
                    <p className="message">Your search has no results – but we still have lots to show you!<br/>Try using a different search keyword.</p>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <p className="message">We found {searchResponse.count} results.</p>
                <Paginator currentItemsLength={searchResponse.results.length} total={searchResponse.count} btnClickHandler={paginatorClickHandler}>
                    <Grid data={searchResponse.results} isAutoScroll={true} isDialog={true} scrollRef={FullScreenDialogRef}/>
                </Paginator>
            </React.Fragment>
        );
    };

    return (
        <FullScreenDialog extraClass="search-dialog" ref={FullScreenDialogRef}>
            <button className="button no-border close-button" onClick={closeDialog}>
                <Icon className="icon large" name="close" />
            </button>
            <form className="form search-dialog-form" onSubmit={(event) => event.preventDefault()}>
                <h5 className="heading">Type in your search and press enter</h5>
                <input
                    className="input"
                    name="query"
                    type="text"
                    autoComplete="off"
                    value={searchText}
                    onChange={searchTextChangeHandler}
                    autoFocus
                />
            </form>
            {searchText && (
                <div className="search-results-wrapper">
                    {isLoading && <Loader />}
                    {searchResponse.count !== null && renderResults()}
                </div>
            )}
        </FullScreenDialog>
    );
};

SearchDialog.propTypes = {
    toggleSearchDialog: PropTypes.func.isRequired
};

export default SearchDialog;
