import Content from '../models/content';
import ContentBlocks from '../models/content-blocks';
import DateHelper from 'app/utilities/date-helper';
import Event from '../models/event';
import News from '../models/news';
import Show from '../models/show';
import Venue from '../models/venue';

export const formatFeaturedGridData = (featuredShowData) => {
    const grid = (featuredShowData.data || []).map((gridItem) => {
        if (gridItem.type === 'shows') {
            return new Show(gridItem).tile;
        } else if (gridItem.type === 'events') {
            return new Event(gridItem).tile;
        }
    });

    return grid;
};

export const formatFeaturedShowData = (show) => {
    if (!show) {
        return null;
    }

    const { name, date, genre, thumbnail, price, venue, slug } = new Show(show);

    return {
        name,
        date,
        thumbnail,
        price,
        venue,
        genre,
        url: `/show/${slug}`
    };
};


export const formatShowData = (apiData, date) => {
    const performances = [];

    apiData.data.map((item) => {
        if (item.type === 'shows') {
            const show = new Show(item, apiData.data.included).performancesTiles;

            show.performances = show.performances.filter((performance) => {
                const performanceDate = performance.starts_at;

                if (DateHelper.getDateWithNZTimezone(date).isSame(performanceDate, 'day')) {
                    performances.push({ ...show, ...performance });

                    return true;
                }

                return false;
            });
        }
    });

    // Orders each performance by start at
    performances.sort((a, b) => new Date(a.starts_at) - new Date(b.starts_at));

    return {
        count: apiData.meta.result_count,
        links: apiData.links,
        performances
    };
};

export const formatSearchResults = (apiData) => {
    let count = 0,
        links = '';
    const totalResults = [];

    apiData.forEach((item, index) => {
        totalResults.push(...item.data);
        if (index + 1 === apiData.length) {
            links = item.links;
            count = item.meta.result_count;
        }
    });

    return {
        count,
        links,
        results: totalResults.map((item) => {
            switch (item.type) {
                case 'shows':
                    return new Show(item).tile;
                case 'venues':
                    return new Venue(item).tile;
                case 'pages':
                    return new Content(item).tile;
                case 'events':
                    return new Event(item).tile;
                case 'news':
                    return new News(item).tile;
            // no default
            }
        })
    };
};

export const formatVenuesData = (venues) => {
    return {
        meta: {
            description: '',
            keywords: '',
            title: 'Venues'
        },
        openGraph: {
            name: 'Venues',
            image: '' // TODO: Fill out a 'default' image
        },
        venues: venues.map((venue) => new Venue(venue).tile),
        isPublished: true
    };
};

export const formatPageData = (page, pageIncluded = []) => {
    let templateData = page.attributes;

    if (page.type === 'shows') {
        templateData = formatShowPageData(page, pageIncluded);
    }

    if (page.type === 'events') {
        templateData = formatEventPageData(page);
    }

    if (page.type === 'venues') {
        templateData = formatVenuePageData(page, pageIncluded);
    }

    return templateData;
};

export const formatShowPageData = (data, included) => {
    // Get data from API request, or throw error if page doesn't exist
    const show = new Show(data, included);

    const contentBlocks = new ContentBlocks(show.blocks);
    const blocksAfterPerformances = new ContentBlocks(show.blocksAfterPerformances);

    return {
        contentBlocks: contentBlocks.blocks,
        blocksAfterPerformances: blocksAfterPerformances.blocks,
        meta: {
            description: show.seo.description,
            keywords: show.seo.keywords,
            title: show.pageTitle
        },
        openGraph: {
            name: show.name,
            image: show.heroImage.desktop || show.thumbnail.landscape
        },
        isPublished: show.props.is_published,
        show
    };
};

export const formatEventPageData = (data) => {
    const event = new Event(data);
    const contentBlocks = new ContentBlocks(event.blocks);

    return {
        blocks: contentBlocks.blocks,
        meta: {
            description: event.seo.description,
            keywords: event.seo.keywords,
            title: event.pageTitle
        },
        openGraph: {
            name: event.name,
            image: event.mainImage || event.thumbnail.landscape
        },
        isPublished: event.props.is_published,
        event
    };
};

export const formatVenuePageData = (data, included) => {
    const venue = new Venue(data, included);
    const contentBlocksAbout = new ContentBlocks(venue.blocksAbout);
    const contentBlocksHire = new ContentBlocks(venue.blocksHire);
    const contentBlocksInfo = new ContentBlocks(venue.blocksInfo);

    return {
        meta: {
            description: venue.seo.description,
            keywords: venue.seo.keywords,
            title: venue.name
        },
        openGraph: {
            name: venue.name,
            image: venue.heroImage.desktop || venue.thumbnail.landscape
        },
        showList: venue.shows,
        venue: venue,
        contentBlocks: {
            about: contentBlocksAbout.blocks,
            hire: contentBlocksHire.blocks,
            info: contentBlocksInfo.blocks
        },
        isPublished: venue.props.is_published,
    };
};
